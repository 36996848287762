import React from "react"
import styled from "styled-components"
import Product from "./Product"

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-block-start: 50px;
  /* padding: 50px 0 50px; */
`
export default function ProductList({ products }) {
  const conditionalLogo = ({ brand }) => {
    if (brand.slug === "naturel-bread") {
      return brand.subLogo1.childImageSharp.fluid || null
    }
    return brand.defaultLogo.childImageSharp.fluid || null
  }
  return (
    <StyledContainer>
      {products.map(product => (
        <Product
          key={product.name}
          productImageSrcset={product.mainImage.childImageSharp.fluid}
          name={product.name}
          text={product.catchPhrase}
          brandImageSrcset={conditionalLogo({ brand: product.brand })}
          productSlug={product.slug}
          brandSlug={product.brand.slug}
        />
      ))}
    </StyledContainer>
  )
}
