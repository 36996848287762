import React from "react"

function setInitialTag(tags) {
  return {
    newProduct: {
      id: tags[0].strapiId,
      name: tags[0].tag,
      isActive: false,
      description: "new product",
    },
    premiumProduct: {
      id: tags[1].strapiId,
      name: tags[1].tag,
      isActive: false,
      description: "premium product",
    },
    walnutProduct: {
      id: tags[2].strapiId,
      name: tags[2].tag,
      isActive: false,
      description: "walnut product",
    },
  }
}

/**
 * Custom React hook to manage tag state.
 * @param {Array} tags - An array of tag objects.
 * @returns {Object} - An object containing tagState, setTagState, and resetTagState functions.
 */
export function useTag(tags) {
  const [tagState, setTagState] = React.useState(setInitialTag(tags))
  const resetTagState = () => setTagState(setInitialTag(tags))
  return {
    tagState,
    setTagState,
    resetTagState,
  }
}
