import React from "react"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

const StyledContainer = styled.div`
  width: 52%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  .gatsby-image-wrapper {
    margin-block-start: 10px;
    width: 28%;

    ${() =>
      media.sp(css`
        margin: 0;
      `)}
  }
  h5 {
    font-weight: bold;
    font-size: 0.9em;
  }
  p {
    min-height: 64px;
    font-size: 0.8em;
    line-height: 1.05em;
    padding: 10px 0;
    margin: 0;
  }
`
export default function ProductText({ text, name, brandLogoSrcset }) {
  return (
    <StyledContainer>
      <Image fluid={brandLogoSrcset} />
      <p>{text}</p>
      <h5>{name}</h5>
    </StyledContainer>
  )
}
