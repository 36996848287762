// export const addPointZero = str => {
//   const strSplittedWithDot = str.split(".")

//   if (
//     !str.startsWith("0") &&
//     str.endsWith("g" || "mg") &&
//     strSplittedWithDot.length === 1
//   ) {
//     const [numStr, unitname] = strSplittedWithDot[0].split(/(g|mg)/)
//     return `${numStr}.0${unitname}`
//   }

//   return str
// }

// control the number of characters in the string
const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str
}

export const truncateCatchPhrase = str => {
  return truncate(str, 39)
}
