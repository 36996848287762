import { useState, useRef, useEffect } from "react"
import { matchSorter } from "match-sorter"
import { useTag } from "./useTag"
import { useSearchInput } from "../../../contexts"

function sortByBrandOrder(results = []) {
  if (!results.length) return null
  results.sort((a, b) => a.brand.order - b.brand.order || a.order - b.order)
}

/**
 * Custom hook for product search functionality.
 *
 * @param {Array} {products} - The array of products to search through.
 * @param {Function} {resetTagState} - The function to reset the tag state.
 * @returns {Object} - An object containing the search input reference, search results, and event handlers.
 */

export function useProductSearch({ products, tags }) {
  const { tagState, setTagState, resetTagState } = useTag(tags)
  const { searchInput, setSearchInput } = useSearchInput()
  const tagListRef = useRef([])
  const timeoutId = useRef(null)
  const searchInputRef = useRef(null)
  const [searchResults, setSearchResults] = useState(products)
  const [isDirty, setIsDirty] = useState(false)
  /**
   * Handles the change event of the search input field.
   * Filters the items based on the search value, sorts them by brand order,
   * resets the tag state, and updates the search results.
   *
   * @param {string} val - The search value entered by the user.
   */
  const handleSearchChanged = val => {
    const results = filterItems(String(val).trim().toLowerCase())
    sortByBrandOrder(results)
    resetTagState()
    tagListRef.current = []
    setSearchInput({ keyword: val, tag: [] })
    setSearchResults(() => {
      if (!isDirty) {
        setIsDirty(true)
      }
      return results
    })
  }

  /**
   * Handles the change of the selected tag ID for filtering items by tag name.
   * @param {number} tagId - The ID of the selected tag.
   */
  const handleSearchByTagnameChanged = tagId => {
    if (tagListRef.current.includes(tagId)) {
      tagListRef.current = tagListRef.current.filter(id => id !== tagId)
    } else {
      tagListRef.current.push(tagId)
    }
    const results = filterItemsByTagname()
    setSearchInput({ keyword: "", tag: [...tagListRef.current] })
    searchInputRef.current.value = ""
    sortByBrandOrder(results)
    setSearchResults(() => {
      if (!isDirty) {
        setIsDirty(true)
      }
      setSearchResults(results)
      return results
    })
  }
  // This code filters the products based on a list of queries.
  // The queries are first split by spaces, and then each query is used to
  // filter the products.
  // The queries are matched against the product's name, catchPhrase, and brand
  // name. The matchSorter library is used to perform the matching.
  const filterItems = queries => {
    if (!queries || !queries.length) return products
    const terms = queries.trim().replace("　", " ").split(" ")
    return terms.reduceRight((items, term) => {
      return matchSorter(items, term, {
        keys: [
          { threshold: matchSorter.rankings.CONTAINS, key: "name" },
          { threshold: matchSorter.rankings.CONTAINS, key: "catchPhrase" },
          {
            threshold: matchSorter.rankings.STARTS_WITH,
            key: "brand.keyword",
          },
        ],
      })
    }, products)
  }

  /**
   * Filters the products by tag ID.
   * @param {number} tagId - The ID of the tag to filter by.
   * @returns {Array} - The filtered list of products.
   */
  const filterItemsByTagname = () => {
    return tagListRef.current.reduceRight((items, id) => {
      return matchSorter(items, id, {
        keys: [
          {
            threshold: matchSorter.rankings.EQUAL,
            key: items => items.tags.map(tag => tag.id),
          },
        ],
      })
    }, products)
  }

  /**
   * Handles the change event of the search input field and triggers a search after a delay of 500ms.
   * @param {Object} e - The event object.
   */
  const inputChangeHandler = e => {
    // React 16 needs this, But React 17 don't support e.persist() anymore
    e.persist()
    searchInputRef.current.value = e.target.value
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      handleSearchChanged(searchInputRef.current.value)
    }, 500)
  }

  useEffect(() => {
    const recreateSearchResultsFromLastKeyword = () => {
      searchInputRef.current.value = searchInput.keyword
      handleSearchChanged(searchInput.keyword)
    }

    const recreateSearchResultsFromLastTags = () => {
      tagListRef.current = [...searchInput.tag]
      const newTags = { ...tagState }
      tagListRef.current.forEach(tagId => {
        if (tagId === 1) {
          newTags.newProduct.isActive = true
        } else if (tagId === 2) {
          newTags.premiumProduct.isActive = true
        } else if (tagId === 3) {
          newTags.walnutProduct.isActive = true
        }
        setTagState(newTags)
      })
      const results = filterItemsByTagname()
      sortByBrandOrder(results)
      setSearchResults(() => {
        if (!isDirty) {
          setIsDirty(true)
        }
        setSearchResults(results)
        return results
      })
    }

    if (searchInput.keyword) {
      recreateSearchResultsFromLastKeyword()
    } else if (searchInput.tag.length > 0) {
      recreateSearchResultsFromLastTags()
    } else {
      setSearchResults(products)
    }
  }, [])

  return {
    searchInputRef,
    searchResults,
    handleSearchByTagnameChanged,
    inputChangeHandler,
    isDirty,
    setIsDirty,
    tagState,
    setTagState,
  }
}
