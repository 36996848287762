import React from "react"
import styled from "styled-components"
import { ProductList } from "../product-list"

const StyledNotification = styled.p`
  text-align: center;
`

/**
 * Renders the search result component.
 * @param {Object[]} results - An array of search results.
 * @param {boolean} isDirty - A boolean indicating if the search input has been modified.
 * @returns {JSX.Element} The rendered SearchResult component.
 */
const SearchResult = ({ results = [], isDirty = false }) => {
  const renderSearchResultText = () => {
    if (!isDirty) return ``
    if (results.length > 0) return `該当する商品が${results.length}点あります`
    if (results.length === 0) return `該当する商品はありません`
  }

  return (
    <>
      <StyledNotification>{renderSearchResultText()}</StyledNotification>
      {results.length ? <ProductList products={results} /> : null}
    </>
  )
}

export default SearchResult
