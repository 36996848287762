import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { ProductSearch } from "../features/product-search"

export default function ProductSearchPage({ data, location }) {
  const {
    allStrapiProducts: { nodes: products },
    allStrapiTags: { nodes: tags },
  } = data

  return (
    <Layout>
      <SEO>
        <title>商品検索 | フランソア</title>
        <meta
          name="description"
          content="おいしい。そしてヘルシー。フランソアのパン商品ラインナップ。"
        />
      </SEO>
      <ProductSearch products={products} tags={tags} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allStrapiProducts(
      filter: { isDisplay: { eq: true } }
      sort: { order: [ASC, ASC], fields: [brand___order, order] }
    ) {
      nodes {
        name
        slug
        catchPhrase
        order
        excerpt
        brand {
          keyword
          order
          slug
          defaultLogo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          subLogo1 {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            id
          }
        }
        tags {
          id
          tag
        }
      }
    }
    allStrapiTags {
      nodes {
        strapiId
        tag
      }
    }
  }
`
