import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import { useProductSearch, useTag } from "./hooks"
import ProductSearchByTag from "./ProductSearchByTag"
import SearchResult from "./SearchResult"
import MainImageBlock from "./MainImageBlock"

const StyledContainers = styled.div`
  padding: 50px 5% 100px;
  h3 {
    font-size: 1.32em;
    line-height: 1.62em;
  }
`

const StyledCategory = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1.62em;
  justify-content: flex-start;
  #keywordButton {
    background: white;
    border: 3px solid ${({ theme }) => theme.colors.primary.red};
    color: ${({ theme }) => theme.colors.primary.red};
  }
  button {
    border-radius: 5px;
    margin: 0 0% 20px 1%;
    height: 50px;
    padding: 0 1.62em;
    font-size: 0.9em;
    width: 32%;
    text-align: center;
  }
  ${() =>
    media.sp(css`
      button {
        width: 48%;
        margin: 5px 1%;
      }
    `)}
`

/**
 * Renders a product search component.
 * @param {Object} props - The component props.
 * @param {Array} props.products - The array of products to search from.
 * @param {Array} props.tags - The array of tags to filter the search results.
 * @returns {JSX.Element} - The rendered component.
 */
export default function ProductSearch({ products, tags }) {
  // const { tagState, setTagState, resetTagState } = useTag(tags)

  const {
    searchInputRef,
    searchResults,
    handleSearchByTagnameChanged,
    inputChangeHandler,
    isDirty,
    tagState,
    setTagState,
  } = useProductSearch({ products, tags })

  return (
    <>
      <MainImageBlock
        inputChangeHandler={inputChangeHandler}
        inputRef={searchInputRef}
      />
      <StyledContainers>
        <StyledCategory>
          <ProductSearchByTag
            tagState={tagState}
            setTagState={setTagState}
            searchFunc={handleSearchByTagnameChanged}
          />
        </StyledCategory>
        <SearchResult results={searchResults} isDirty={isDirty} />
      </StyledContainers>
    </>
  )
}
