import React from "react"
import styled from "styled-components"

const StyledButton = styled.button.withConfig({
  shouldForwardProp: prop => prop !== "isActive",
})`
  background-color: ${props =>
    props.isActive
      ? props.theme.colors.primary.green
      : props.theme.colors.primary.white};
  border: solid 3px ${props => props.theme.colors.primary.green};
  color: ${props =>
    props.isActive
      ? props.theme.colors.primary.white
      : props.theme.colors.primary.green};
  margin-left: 10px;
`
/**
 * A button component for searching products by tag.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.tag - The tag object to display.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @param {string} props.tagKey - The key of the tag object in the state.
 * @param {Function} props.setTagState - The function to update the tag state.
 * @returns {JSX.Element} - The SearchByTagButton component.
 */
export default function SearchByTagButton({
  tag,
  onClick,
  tagKey,
  setTagState,
}) {
  const handleButtonClick = () => {
    const updatedTag = { ...tag, isActive: !tag.isActive }
    setTagState(prev => ({ ...prev, [tagKey]: updatedTag }))
    onClick(tag.id)
  }

  return (
    <StyledButton isActive={tag.isActive} onClick={handleButtonClick}>
      {tag.name}
    </StyledButton>
  )
}
