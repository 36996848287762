import React from "react"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

const StyledCotnainer = styled.div`
  width: 45%;
  .gatsby-image-wrapper {
    width: 100%;
    ${() =>
      media.sp(css`
        width: 90%;
      `)}
  }
`
export default function ProductImage({ imageSrcset }) {
  return (
    <StyledCotnainer>
      <Image fluid={imageSrcset} />
    </StyledCotnainer>
  )
}
