import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import ProductImage from "./ProductImage"
import ProductText from "./ProductText"
import { truncateCatchPhrase } from "../../utils/helper"

const StyledContainer = styled.div`
  width: 30%;
  margin: 20px 1.5%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  ${() =>
    media.tablet(css`
      width: 46%;
      margin: 20px 2%;
    `)}
  ${() =>
    media.sp(css`
      width: 100%;
      margin: 5px auto;
      align-items: center;
    `)}
`

export default function Product({
  productImageSrcset,
  brandImageSrcset,
  text,
  name,
  brandSlug,
  productSlug,
}) {
  return (
    <StyledContainer>
      <ProductImage imageSrcset={productImageSrcset} />
      <ProductText
        text={text && truncateCatchPhrase(text)}
        name={name}
        brandLogoSrcset={brandImageSrcset}
      />
      <Link to={"/" + brandSlug + "/" + productSlug + "/"}></Link>
    </StyledContainer>
  )
}
