import React from "react"
import SearchByTagButton from "./SearchByTagButton"

/**
 * Renders a list of search buttons based on the given tag state.
 * @param {Object} props - The component props.
 * @param {Function} props.searchFunc - The function to call when a search button is clicked.
 * @param {Object} props.tagState - The current tag state.
 * @param {Function} props.setTagState - The function to call when the tag state is updated.
 * @returns {JSX.Element[]} An array of search buttons.
 */
const ProductSearchByTag = ({ searchFunc, tagState, setTagState }) => {
  return Object.keys(tagState).map(key => (
    <SearchByTagButton
      key={tagState[key].id}
      tag={tagState[key]}
      tagKey={key}
      onClick={searchFunc}
      setTagState={setTagState}
    />
  ))
}

export default ProductSearchByTag
