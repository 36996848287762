import React, { useContext, useEffect } from "react"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { useInView } from "react-intersection-observer"

import { HeaderOverWrappedContext } from "../hooks/HeaderOverWrappedContext"

const StyledHeadWrapper = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .headerBack {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .headerTitle {
    width: ${props => props.titleWidth || "70%"};
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 5px grey;
    .headerLogo {
      width: 240px;
    }
    h2 {
      color: white;
      font-size: 1.62em;
      padding-bottom: 0.62em;
    }
    p {
      color: white;
      padding: 0.32em 0;
      text-align: center;
    }
  }
  ${() =>
    media.sp(css`
      height: 350px;
      .headerTitle .headerLogo {
        width: 100%;
      }
      .headerTitle h2 {
        font-size: 1.32em;
        padding-bottom: 0;
        margin-top: 0.32em;
      }
      .headerTitle p {
        font-size: 0.8em;
      }
    `)}
`

const HeaderVisual = props => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
  })
  const [state, setState] = useContext(HeaderOverWrappedContext)
  useEffect(() => {
    setState(state => ({ ...state, isDisplay: !inView }))
  }, [inView])

  return (
    // <StyledHeadWrapper>
    <StyledHeadWrapper ref={ref} titleWidth={props.titleWidth}>
      <div className="headerBack">
        {props.backImage && <Image fluid={props.backImage} />}
        {props.backSVG && <img src={props.backSVG} alt="背景画像" />}
      </div>
      <div className="headerTitle">
        <h2>{props.mainTitle && props.mainTitle}</h2>
        <div className="headerLogo">
          {props.mainLogo && <Image fluid={props.mainLogo} />}
        </div>
        {props.mainText && (
          <p dangerouslySetInnerHTML={{ __html: props.mainText }} />
        )}
        {props.component && props.component}
      </div>
    </StyledHeadWrapper>
  )
}

export default HeaderVisual
