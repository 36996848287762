import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import HeaderVisual from "../../components/HeaderVisual"
import { media } from "../../styles/media"

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const StyledInput = styled.input`
  max-width: 600px;
  width: 100%;
  height: 56px;
  background-color: white;
  border-radius: 5px;
  opacity: 0.9;
  background-image: url(${props => props.searchIcon});
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 8px 50%;
  padding-inline: 40px 16px;
  ${() =>
    media.sp(css`
      margin-top: 20px;
    `)}
  :hover {
    opacity: 0.95;
    cursor: pointer;
  }
  :focus {
    opacity: 1;
    cursor: text;
  }
  ::placeholder {
    font-size: 14px;
  }
`

/**
 * Renders the main image block for the product search feature.
 * @param {Object} props - The component props.
 * @param {Function} props.inputChangeHandler - The function to handle input changes.
 * @param {Object} props.inputRef - The reference to the input element.
 * @returns {JSX.Element} - The JSX element representing the main image block.
 */
export default function MainImageBlock({ inputChangeHandler, inputRef }) {
  const { searchIcon, mainVisual } = useStaticQuery(query)
  // hiding the smart keyboard after tapping the search button on mobile devices
  const submitHandler = e => {
    e.preventDefault()
    inputRef.current.blur()
  }
  return (
    <div style={{ position: "relative" }}>
      <HeaderVisual
        mainTitle="商品検索"
        backImage={mainVisual.childImageSharp.fluid}
        titleWidth="90%"
        component={
          <StyledForm onSubmit={submitHandler}>
            <StyledInput
              type="search"
              placeholder="商品名などのキーワードを入力してください"
              ref={inputRef}
              onChange={e => inputChangeHandler(e)}
              searchIcon={searchIcon.publicURL}
            />
          </StyledForm>
        }
      />
    </div>
  )
}

const query = graphql`
  query {
    searchIcon: file(relativePath: { eq: "search-icon.svg" }) {
      publicURL
    }
    mainVisual: file(relativePath: { eq: "varieties.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
