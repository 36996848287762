/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// function SEO({ description, lang, meta, title, children }) {
function SEO({ jsonld = null, children }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)
  const defaultTitle = siteMetadata.title

  // Default Website Schema
  let schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: siteMetadata.siteUrl,
      name: defaultTitle,
      // alternateName: headline ? headline : "",
    },
  ]
  if (jsonld) schemaOrgJSONLD.push(jsonld)

  return (
    <Helmet>
      <html lang={siteMetadata.lang} />
      <title>{defaultTitle}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta name="image" content={siteMetadata.image || ""} />
      <meta
        name="apple-mobile-web-app-title"
        content={siteMetadata.shortName}
      />
      <meta name="application-name" content={siteMetadata.shortName} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {children}

      {/* OpenGraph  */}
      <meta
        property="og:title"
        content="フランソア | 毎日食べるパンから食物繊維を。"
      />
      <meta property="og:site_name" content="フランソア" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.SITE_URL} />
      <meta property="og:description" content="" />
      <meta
        property="og:image"
        content={`${process.env.SITE_URL}/images/francois_img02.jpg`}
      />
      <meta property="fb:app_id" content="147844738673476" />

      {/* Twitter Card */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} /> */}
    </Helmet>
  )
}

// SEO.defaultProps = {
//   lang: `ja`,
//   title: "",
//   meta: [],
//   description: ``,
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        lang
        description
        author
        siteUrl
        shortName
      }
    }
  }
`

export default SEO
